@use '@nex/labs/src/styles/common.scss' as *;

.InnerWrapLayout {
  background: var(--primary-white);
  border: 1px solid var(--border-light);
  box-shadow: 8px 8px 32px rgba(0, 0, 0, 0.04);
  border-radius: 24px;
  padding: 48px 32px;
  max-width: 600px;

  .Divider {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin: 12px 0;
    @include typography('p');
    font-weight: 700;
    color: bg('white', 'heavy');
    &::before,
    &::after {
      content: '';
      flex: 1;
      height: 1px;
      background: bg('white', 'soft');
    }
  }

  .BackButton {
    padding-left: 0;
    position: absolute;
    top: 30px;
    margin: 0;
    right: 22px;
    gap: 14px !important;
    opacity: 0.7;
  }
}

.GoogleAuthButton {
  position: absolute;
  width: 100%;
  will-change: opacity;
  opacity: 0.001;
  transform: scale(1.9);
  z-index: 1;

  iframe {
    margin: auto !important;
  }
}

.OnboardingButton {
  background: var(--primary-white);
  cursor: pointer;
  border: 2px solid var(--border-light);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 18px 24px;
  gap: 12px;
  max-width: 50%;
  flex: 1 1 48%;
  text-align: center;
  transition: all 0.2s ease;

  @include media('md', 'max') {
    max-width: 100%;
    flex: 1 1 100%;
  }

  &.isActive {
    border: 2px solid var(--primary-theme);

    > span {
      background: var(--primary-theme);
    }
  }

  > span {
    background: bg('black', '6');
    border-radius: 50%;
    padding: 8px;
    height: 50px;
    width: 50px;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
