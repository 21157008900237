@use '@nex/labs/src/styles/common.scss' as *;

.AnimatorLayout {
  --aside-width: 300px;
  --main-width: calc(100% - var(--aside-width));
  --header-menu-spacing: 16px;

  background: var(--primary-gray);

  @include media('xl', 'max') {
    --aside-width: 280px !important;
  }
  @include media('lg', 'max') {
    --aside-width: 0px !important;
  }

  .AnimatorLayoutSidebar {
    @include media('lg', 'max') {
      position: fixed;
      left: 0;
      top: 0;
      transform: translateX(-100%);
    }
  }

  .AnimatorLayoutSidebarOpen {
    transform: translateX(0);
    z-index: 9999;
    width: 100%;
    height: 100%;

    .Backdrop {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: -1;
    }

    > div {
      flex-wrap: nowrap;
      width: 80%;
    }
  }

  &Main {
    margin-left: var(--aside-width);
    width: var(--main-width);
    position: relative;
    min-height: var(--nex-labs-vh);
    height: auto;
    background-color: var(--primary-gray);
  }
}

.FloatingNav {
  background: var(--primary-gray);
  padding: 4px;
  left: 50%;
  transform: translateX(-50%);
  position: fixed;
  display: flex;
  align-items: center;
  gap: 8px;
  bottom: 18px;
  z-index: 100;
  background: var(--primary-white);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
}
