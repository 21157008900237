.SketchSettings {
  fieldset {
    [data-amlabs-field-wrapper] {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
    }

    p {
      margin: 0;
      font-size: 14px;
      font-weight: 600;
    }

    input {
      padding: 8px 8px;
      font-size: 14px;
      background: transparent !important;

      &:focus {
        border-bottom: 1px solid var(--primary-theme);
      }
    }

    :global(.leading-icon) {
      p {
        font-size: 12px;
      }
      margin: 8px 0px 8px 0px;
    }
  }
}

.ColorPicker {
  width: 100%;
  button {
    border: 1px solid var(--border-light);
    width: 100%;
    padding: 12px;
    height: fit-content;
    border-radius: 4px;

    &:disabled {
      opacity: 0.5;
    }
  }

  input {
    font-size: 12px !important;
  }
}
