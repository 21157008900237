@use '@nex/labs/src/styles/common.scss' as *;

.ArtboardAsidePreview {
  width: var(
    --preview-width,
    calc(var(--aside-width) - calc(var(--aside-width) / 5))
  );
  overflow-y: auto;
  height: var(--nex-labs-vh);
  background: var(--primary-white);
  position: fixed;
  top: 0;
  right: 0;

  z-index: 100;
  padding: 14px;
  box-shadow: -1px 0px 3px rgba(0, 0, 0, 0.05);

  @include custom-scrollbar;

  @include media('xl', 'max') {
    transform: translateX(100%);
    padding: 0px;

    &.isShow {
      width: 100%;
      max-width: 250px;
      height: 100%;
      transform: translateX(0);
      z-index: 10001;
      transition: all 0.2s ease;
    }
  }

  .Remix {
    background: var(--primary-gray);
    padding: 8px;
    border-radius: 6px;
    display: flex;
    gap: 8px;
    flex-direction: column;
  }

  &.snap {
    animation: snap 0.1s ease;
  }

  @keyframes snap {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-1px);
    }
    50% {
      transform: translateX(1px);
    }
    75% {
      transform: translateX(-1px);
    }
    100% {
      transform: translateX(0);
    }
  }

  .ResizeHandler {
    width: 6px;
    height: 24px;
    border-radius: 10px;

    cursor: ew-resize;
    background-color: #d9d9d9;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
  }
}

.BlockPreview {
  background: var(--primary-white);
  border: 1px solid var(--border-light);
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;

  > img {
    height: 70px;
    width: 90px;
  }
}

.ArtboardAsidePreviewOverlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  display: none;
  backdrop-filter: blur(10px);
}

@include media('lg', 'max') {
  .ArtboardAsidePreviewOverlay {
    &.isShow {
      display: block;
    }
  }
}
