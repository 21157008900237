@use '@nex/labs/src/styles/common.scss' as *;

.ArtboardAside {
  &.realtime {
    --aside-width: 75px;
    transition: width 0.1s ease;
    .ArtboardBlocksInnerPage {
      display: none;
    }

    &:hover {
      --aside-width: 325px;
      .ArtboardBlocksInnerPage {
        display: block;
      }
    }
  }
  width: var(--aside-width);
  height: var(--nex-labs-vh);
  background: var(--primary-white);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  padding: 8px;
  box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.05);

  display: flex;
  justify-content: space-between;

  gap: 8px;

  @include media('md', 'max') {
    flex-wrap: wrap;
  }

  .ArtboardAsideNav {
    max-width: 60px;
    flex: 1;
    display: flex;
    padding: 12px 0;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    align-items: center;

    .topNav {
      display: flex;
      flex-direction: column;
      gap: 2px;
      align-items: center;
      justify-content: center;

      .active {
        opacity: 1;
        transition: all 0.2s ease;

        &::before {
          content: '';
          background: var(--primary-gray);
          border-radius: 5px;
          position: absolute;
          left: 50%;
          top: 47%;
          z-index: -1;
          height: calc(100% + 4px);
          width: calc(100% + 12px);
          transform: translate(-50%, -50%);
        }

        svg {
          path {
            stroke: var(--text-black);
          }
        }
      }
    }

    button {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 6px;
      font-weight: 600;
      font-size: var(--font-caption);
      padding: 6px;
      border-radius: 6px;

      color: var(--text-black);
      opacity: 0.8;

      svg {
        width: 18px;
        height: 18px;
      }

      &:hover {
        &::before {
          opacity: 0.8;
          content: '';
          background: var(--primary-gray);
          border-radius: 5px;
          position: absolute;
          left: 50%;
          top: 47%;
          z-index: -1;
          height: calc(100% + 4px);
          width: calc(100% + 12px);
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  @include media('lg', 'max') {
    .ArtboardBlocksInnerPage {
      display: none;
    }

    transform: translateX(-100%);

    .ArtboardAsideNav {
      display: none;
    }

    &.isShow {
      transform: translateX(0);

      width: 100%;
      max-width: 350px;
      height: 100%;
      z-index: 10001;
      transition: all 0.2s ease;

      .ArtboardBlocksInnerPage,
      .ArtboardAsideNav {
        display: block;
      }
    }
  }

  .ArtboardBlocksInnerPage {
    flex: 1;
    max-width: 77%;
    max-height: 100%;
    padding: 14px 0px 14px 8px;

    .BlockInnerPageScroll {
      max-height: 100%;
      overflow-x: auto;

      @include custom-scrollbar();
    }
  }
}

.ArtboardAsideOverlay {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  display: none;
  backdrop-filter: blur(10px);
}

@include media('lg', 'max') {
  .ArtboardAsideOverlay {
    &.isShow {
      display: block;
    }
  }
}
